<template>
  <li :class="classes">
    <aside class="cart-product__aside">
      <output
        class="cart-product__qty"
        v-if="!editable"
      >{{ item.quantity }}x</output>

      <!-- QTY -->
      <forms-qty
        class="cart-product__qty"
        :noIncrease="!canIncrease"
        :noDecrease="!canDecrease"
        :min="0"
        :size="$pepper.Size.S"
        @change="onChangeQuantity(item)"
        v-if="editable"
        v-model.number="item.quantity"
      />
    </aside>

    <div class="cart-product__content">
      <!-- Header -->
      <header class="cart-product__header">
        <!-- Warning::Allergens -->
        <ui-icon
          class="cart-product__warn"
          glyph="info-circle"
          :title="title"
          v-if="hasAllergens"
        />

        <!-- Name -->
        <div class="cart-product__title">{{ item.product.name }}</div>

        <!-- Price -->
        <div class="cart-product__price">
          <span>{{ itemPrice(item) }}</span>
        </div>
      </header>

      <!-- Body -->
      <component
        :is="productType"
        :item="item"
      />
    </div>
  </li>
</template>

<script>
import { CartItem } from '@sayl/front-core'
import CartProductBundle from './cart-product-bundle'
import CartProductDefault from './cart-product-default'
import CartProductGiftVoucher from './cart-product-gift-voucher'

import FormsQty from '@/components/forms/qty'

import MixinAllergens from '../../mixins/allergens'
import MixinCurrency from '@/helpers/currency'
import MixinError from '../../mixins/error'
import MixinMultishop from '@/helpers/multishop'

export default {
  name: 'CartProduct',

  components: {
    FormsQty,
    CartProductBundle,
    CartProductDefault,
    CartProductGiftVoucher
  },

  mixins: [
    MixinAllergens,
    MixinCurrency,
    MixinError,
    MixinMultishop,
  ],

  props: {
    editable: {
      type: Boolean,
      default: false
    },

    item: {
      type: CartItem,
      required: true
    }
  },

  inject: [
    '$cart'
  ],

  data() {
    return {
      debounce: null,
      enabled: true
    }
  },

  computed: {
    allergens() {
      let allergens = this.$basil.get(this.oat, 'allergens', [])
      return allergens
    },

    allergensList() {
      let allergens = this.$basil.get(this.item, 'product.allergens', [])
      let ret = this.allergens.filter(a => allergens.includes(a.id));
      return ret.map(a => this.getAllergenName(a))
    },

    canIncrease(){
      return this.enabled
    },

    canDecrease(){
      return this.enabled
    },

    classes(){
      return {
        'cart-product': true,
        '-editable': this.editable === true,
        '-default': this.isProductDefault === true,
        '-bundle': this.isProductBundle === true
      }
    },

    hasAllergens() {
      return this.allergensList.length > 0
    },

    isProductBundle(){
      return this.$basil.get(this.item, 'product.type', 'default') === 'bundle'
    },

    isProductDefault(){
      return this.$basil.get(this.item, 'product.type', 'default') === 'default'
    },

    productType() {
      let ret = 'cart-product-default';
      switch(this.$basil.get(this.item, 'product.type', 'default')) {
        case 'bundle':
          ret = 'cart-product-bundle';
          break;
        case 'gift_voucher':
          ret = 'cart-product-gift-voucher';
          break;
      }

      return ret
    },

    title(){
      let l = this.allergensList.join(', ')
      return this.$t('resto.cart_contains_allergen', { allergens: l })
    }
  },

  methods: {
    itemPrice(item){
      return this.toCurrency(item.total.price.value)
    },

    hasPromoPrice(item){
      return !!item.total.promo
    },

    itemPromoPrice(item){
      return this.toCurrency(item.total.promo.value)
    },

    onChangeQuantity() {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.enabled = false
        this.$cart.change({ item: this.item, refresh: this.isMultiShop === true })
          .then(() => this.enabled = true)
          .catch((error) => {
            this.handleError(error)
            this.enabled = true
          })
      }, 300)
    }
  }
}
</script>
