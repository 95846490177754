<template>
  <div class="cart-product__body">
    <div
      class="cart-product__bundle"
      :key="j"
      v-for="(group, j) in groups">
      <!-- Variant -->
      <div class="cart-product__bundle-name">{{ group.variantName }}</div>

      <!-- Options -->
      <ul
        class="cart-product__options"
        v-if="hasOptions(j)">
        <li
          class="cart-product__option"
          :key="i"
          v-for="(option, i) in options(j)">
          <div class="cart-product__option-name">{{ option.title_locale }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { CartItem } from '@sayl/front-core'
import MixinCurrency from '@/helpers/currency'

export default {
  name: 'CartProductBundle',

  mixins: [
    MixinCurrency
  ],

  props: {
    item: {
      type: CartItem,
      required: true
    }
  },

  computed: {
    groups() {
      return this.item &&
              this.item.product &&
              this.item.product.bundle;
    },
  },

  methods: {
    options(index) {
      let ret =  this.hasOptions(index) ?
        this.item.product.bundle[index].options.filter(o => {
          let keys = o.selection ? Object.keys(o.selection) : [];
          let found = keys.filter(k => o.selection[k] && o.selection[k] > 0);
          return o.value != '' && (o.selection != null || found.length > 0 )
        }) : [];
        return ret
    },

    hasOptions(index) {
      return this.item &&
              this.item.product &&
              this.item.product.bundle[index] &&
              this.item.product.bundle[index].options &&
              this.item.product.bundle[index].options.length > 0 &&
              this.item.product.bundle[index].options.filter(o => o.selection != null).length
    },
  },
}
</script>
