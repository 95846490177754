<template>
  <div class="cart-product__body">
    <div
      class="cart-product__variant"
      v-if="isGift">
      <div>{{ $t('resto.cart_product_gift_voucher_for', { name: $basil.get(this.item, 'product.recieverName' ) }) }}</div>
      <div>{{ $t('resto.cart_product_gift_voucher_send_to', { email: $basil.get(this.item, 'product.recieverEmail') }) }}</div>
    </div>

    <div 
      class="cart-product__variant"
      v-if="validityPeriodDate">
      {{ $t('resto.product_validity_period', { date: validityPeriodDate, days: validityPeriod }) }}
    </div>
  </div>
</template>

<script>
import { CartItem } from '@sayl/front-core'
import MixinCurrency from '@/helpers/currency'

import moment from 'dayjs'

export default {
  name: 'CartProductGiftVoucher',

  mixins: [
    MixinCurrency
  ],

  props: {
    item: {
      type: CartItem,
      required: true
    }
  },

  inject: ['$cart'],

  computed: {
    isGift() {
      return this.$basil.get(this.item, 'product.isGift', false)
    },

    validityPeriod() {
      return this.$basil.get(this.item, 'product.validityPeriod', null) ? 
        this.$basil.get(this.item, 'product.validityPeriod', null) : null
    },

    validityPeriodDate() {
      let ret = this.validityPeriod

      if(ret) {
        ret = moment().add(ret, 'days')
      }

      return ret ? this.$basil.i18n.date(ret.toDate()) : null
    }
  },
}
</script>
