<template>
  <div :class="classes">
    <div
      class="cart__loader"
      v-if="loading">
      <ui-loader />
    </div>

    <!-- Body -->
    <div
      class="cart__body"
      :class="{ '-is-loading': loading }"
      v-if="hasItems && hasShops && shopable">
      <!-- Allergens -->
      <notice-banner
        class="cart__allergens"
        :description="allergensDescription"
        :title="allergensTitle"
        v-if="hasAllergens"
      />

      <article
        class="cart__shops"
        :key="shop.id"
        v-for="(shop) in cartedShops">
        <div class="cart__subheader">
          <p class="cart__shop">{{ shop.name }}</p>

          <data-tag
            class="cart__tag-closed"
            hue="red"
            :size="$pepper.Size.S"
            v-if="isClosed(shop)"
            v-scroll-reveal
          >{{ $t(`resto.closed`) }}</data-tag>
        </div>

        <ul
          class="cart__items">
          <cart-product
            v-for="(item, i) in cartShops[shop.id]"
            :key="i"
            class="cart__item"
            :editable="editable"
            :item="item"
          />
        </ul>
      </article>
    </div>

    <!-- Body -->
    <div
      class="cart__body"
      :class="{ '-is-loading': loading }"
      v-if="hasItems && (!hasShops || !shopable)">

      <!-- Allergens -->
      <notice-banner
        class="cart__allergens"
        :description="allergensDescription"
        :title="allergensTitle"
        v-if="hasAllergens"
      />

      <ul
        v-if="hasItems"
        class="cart__items">
        <cart-product
          v-for="(item, i) in cart.items"
          :key="i"
          class="cart__item"
          :editable="editable"
          :item="item"
          />
      </ul>
    </div>

    <div
      class="cart__body"
      v-if="!hasItems">
      <p class="cart__empty">{{ $t('resto.cart_empty') }}</p>
    </div>

    <!-- Footer (total) -->
    <footer v-if="hasItems" class="cart__footer">
      <ul v-if="editable" class="cart__items">
        <li class="cart__item cart-fee">
          <div class="cart-fee__labels -item">
            <div class="cart-fee__label">{{ $t('resto.cart_footer_subtotal') }}</div>
            <div class="cart-fee__label">{{ toCurrency(total) }}</div>
          </div>
        </li>
      </ul>
      
      <ul v-else class="cart__items">
        <li class="cart__item cart-fee">
          <div class="cart-fee__labels -item">
            <div class="cart-fee__label">{{ $t('resto.cart_footer_subtotal') }}</div>
            <div class="cart-fee__label">{{ toCurrency(cart.subtotal) }}</div>
          </div>
        </li>

        <li class="cart__item cart-fee">
          <div class="cart-fee__labels -item">
            <div class="cart-fee__label">{{ $t('resto.cart_footer_vat') }}</div>
            <div class="cart-fee__label">{{ toCurrency(cart.vat) }}</div>
          </div>
        </li>

        <li v-if="isDelivery" class="cart__item cart-fee">
          <div class="cart-fee__labels -item">
            <div class="cart-fee__label">{{ $t('resto.cart_footer_delivery_cost') }}</div>
            <div class="cart-fee__label">{{ toCurrency(cart.delivery.price) }}</div>
          </div>
        </li>

        <li class="cart__item cart-fee -total">
          <div class="cart-fee__labels -item">
            <div class="cart-fee__label">{{ $t('resto.cart_footer_total') }}</div>
            <div class="cart-fee__label">{{ toCurrency(total) }}</div>
          </div>
        </li>
      </ul>
    </footer>

    <notice-banner 
      :intent="$pepper.Intent.DANGER"
      description="Cannot order, items from closed shop in here"
      v-if="false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import CartProduct from './cart-product'

import MixinAllergens from '../../mixins/allergens'
import MixinCurrency from '@/helpers/currency'
import MixinMultishop from '@/helpers/multishop'
import MixinOrderable from '@/helpers/orderable'

export default {
  name: 'CatalogCart',

  components: {
    CartProduct,
  },

  inject: [
    '$cart',
    '$catalog',
    '$embed',
    '$localStorage',
  ],

  mixins: [
    MixinAllergens,
    MixinCurrency,
    MixinMultishop,
    MixinOrderable,
  ],

  props: {
    editable: {
      type: Boolean,
      default: false
    },

    shopable: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      key: 1
    }
  },

  computed: {
    ...mapState({
      cart: state => state['sayl-front.cart'].cart,
      service: state => state['sayl-front.service'].service,
    }),

    allergensList() {
      let items = this.$basil.get(this.cart, 'items', [])

      let allergens = [];
      items.forEach((item) => {
        let as = this.$basil.get(item, 'product.allergens', [])

        as.forEach(a => {
          if(!allergens.includes(a)) {
            allergens.push(a)
          }
        })
      })

      let ret = this.allergens.filter(a => allergens.includes(a.id));
      return ret.map(a => this.getAllergenName(a))
    },

    allergensDescription() {
      let ret = ''

      this.allergensList.forEach((a, i) => {
        if(i > 0 && i < this.allergensList.length - 1) {
          ret += `, ${a}`
        } else if(i === this.allergensList.length - 1) {
          ret += ` & ${a}`
        } else {
          ret += ` ${a}`
        }
      })

      return this.$t('resto.cart_allergens_description', {allergens: ret})
    },

    allergensTitle() {
      return this.$t('resto.cart_allergens_title')
    },

    classes(){
      return {
        'cart': true,
        '-editable': this.editable === true
      }
    },

    hasAllergens() {
      return this.allergensList.length > 0
    },

    hasItems() {
      this.key++
      return this.cart && this.cart.items && this.cart.items.length > 0
    },

    isDelivery() {
      return this.service.name === 'delivery' && this.cart.delivery != null
    },

    loading() {
      return this.$basil.get(this.$cart, 'loading')
    },

    total() {
      let ret = this.cart.total.value;
      return ret;
    },

    embed() {
      return this.$basil.get(this.$embed, 'embed.model')
    },

    cartedShops() {
      return this.subshops && this.cart && this.subshops.filter((s) => {
        return this.cart.shops[s.id] != null && this.cart.shops[s.id].length > 0
      })
    },

    cartShops() {
      return this.cart && this.cart.shops ? this.cart.shops : {}
    },

    hasShops() {
      return this.isMultiShop && this.cart && Object.keys(this.cart?.shops ?? {}).length > 0
    },
  },

  watch: {
    '$catalog.catalog.catalog.id': {
      deep: true,
      handler() {
        if(this.isMultiShop === true) return

        this.$cart.init({
          embedId: this.$basil.get(this.$embed, 'embed.model.id'),
          shopId: this.$basil.get(this.$embed, 'shop.model.id'),
          service: this.$localStorage.service,
          catalogId: this.$basil.get(this.service, 'menuId', null),
          enrich: this.isMultiShop === true ? 1 : 0,
        })
          .catch((e) => $console.error(e))
      }
    },

    '$embed.shop.model.id': {
      deep: true,
      handler() {
        if(this.isMultiShop !== true) return

        this.$cart.init({
          embedId: this.$basil.get(this.$embed, 'embed.model.id'),
          shopId: this.$basil.get(this.$embed, 'shop.model.id'),
          service: this.$localStorage.service,
          catalogId: this.$basil.get(this.service, 'menuId', null),
          enrich: this.isMultiShop === true ? 1 : 0,
        })
          .catch((e) => $console.error(e))
      }
    },
  },

  // methods: {
  //   isClosed(shop) {
  //     let ret = false 

  //     if(this.service && shop.services) {
  //       let s = shop.services.find(s => s.apiname === this.service.apiname)
  //       if(s) {
  //         ret = !s.isOpen
  //       }
  //     }
      
  //     return ret
  //   },
  // },

  async mounted() {
    // if(this.isMultiShop === true) {
      this.$cart.init({
        embedId: this.$basil.get(this.$embed, 'embed.model.id'),
        shopId: this.$basil.get(this.$embed, 'shop.model.id'),
        service: this.$localStorage.service,
        catalogId: this.$basil.get(this.service, 'menuId', null),
        enrich: this.isMultiShop === true ? 1 : 0,
      })
        .catch((e) => $console.error(e))
    // }

    if(this.isMultiShop && !this.subshops) {
      let shops = await this.$embed.shop.getShops({ childrenOnly: 1 })
      this.$store.commit('sayl-front.catalog.multishop/setShops', shops)
    }
  }
}
</script>
