<template>
  <div class="cart-product__body">
    <div
      class="cart-product__variant"
      v-if="hasVariants"
    >{{ item.product.variant.name }}</div>

    <ul
      class="cart-product__options"
      v-if="hasOptions">
      <li
        class="cart-product__option"
        :key="option"
        v-for="option in options"
      >{{ option }}</li>
    </ul>
  </div>
</template>

<script>
import { CartItem } from '@sayl/front-core'
import MixinCurrency from '@/helpers/currency'

export default {
  name: 'CartProductDefault',
  mixins: [
    MixinCurrency
  ],

  props: {
    item: {
      type: CartItem,
      required: true
    }
  },

  inject: ['$cart'],

  computed: {
    hasOptions(){
      return this.item &&
             this.item.selectedOptions &&
             this.item.selectedOptions.length > 0
    },

    hasVariants() {
      return this.item &&
             this.item.product &&
             this.item.product.variants &&
             this.item.product.variants.length > 1
    },

    options(){
      let o = this.item.selectedOptions || [];
      return o.filter( option => option != null )
    }
  },
}
</script>
